.StartButton {
    outline: none;
    margin: 20px 5px;
    width: 180px;
    padding: 10px;
    background: #78de86;
    border: solid #59985e 3px;
    border-radius: 8px;
    font-family: source-code-pro, monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;;
    color: black;
}
.StartButton:focus {
    border: solid #46ec52 3px;
}

.StartButton:hover {
    cursor: pointer;
    border: solid #46ec52 3px;
    background: #2cd547;
    box-shadow: 0 0 4px #7e7b7b;
}

.StartText {
    color: #282c34;
    position: relative;
    text-align: center;
    margin: 0;
    font-size: 1.2rem;
}

.Disabled {
    background: #464657;
}

.Disabled:hover {
    border: solid #59985e 3px;
    background: #464657;
    box-shadow: none;
    cursor: auto;
}


@media only screen and (max-width: 600px) {
    .StartButton {
        outline: none;
        margin: 10px 4px;
        width: 110px;
    }

    .StartText {
        color: #282c34;
        position: relative;
        text-align: center;
        margin: 0;
        font-size: 1rem;
    }
}

