.StatsTable {
    text-align: center;
    margin: 0;
    color: #ffffff;
}

.StatsTable thead {
    background: #34a434;
}

.StatsTable tbody {
    background: #808080;
}

.StatsTable th {
    padding: 1rem 1.8rem;
}

.StatsTable td {
    padding: 0.5rem;
}

.NoGamesPlayed {
    padding-top: 10px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .StatsTable {
        font-size: 0.7rem;
        margin: 20px;
        color: #ffffff;
    }
    .StatsTable th {
        padding: 1rem 1rem;
    }
}

