.LetterInput {
    color: #6b6b6b;
    text-align: center;
    margin: 40px 10px;
    font-size: 5rem;
    font-family: source-code-pro, monospace;
    border: transparent;
    border-bottom: #eed708 5px solid;
    border-top: 5px transparent dashed;
    background: transparent;
    caret-color: transparent;
    padding: 0.5rem;
    border-radius: 3px;
}

@media only screen and (max-width: 600px) {
    .LetterInput {
        font-size: 2rem;
        margin: 30px 5px 0;
    }
}

input:focus {
    background: #464657;
    outline: none;
    border-bottom: white 5px solid;
}

.letter-input-correct {
    color: #414141;
    background: #78c708;
    border-top: solid #78c708 5px;
    border-bottom: #c1f38c 5px solid;

}

.letter-input-incorrect {
    background: #bd4141;
    border-top: solid #bd4141 5px;
    border-bottom: #e87930 5px solid;
}

.Animate {
    animation-name: colorChange;
    animation-duration: 0.7s;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform: rotateY(360deg);
}

@keyframes colorChange {
    0% {
        background-color: #bd4141;
    }

    50% {
        background-color: #eed708;
    }
    100% {
        background-color: #78c708;
    }
}

@keyframes colorChange {
    from {
        background-color: #bd4141;
    }
    to {
        background-color: #eed708;
    }
}