.Game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    margin: auto;
    max-width: 600px;
}

.Play {
    min-width: 70%;
}

.Counters {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

