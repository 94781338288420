
.EndGameStats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: auto;
}


.SummaryModalTitle {
    margin: 0;
    font-size: 1.3rem;
}

.SummaryHeading {
    font-size: 1.5rem;
    margin-top:30px;
}

.WordOfTheDayAnswer {
    font-size: 2rem;
    margin: 10px;
}

.DataSummary {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-bottom: 1rem;
}

.TimeTaken {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px;
}

.GuessCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3px;
}

.EndGameButton {
    margin: auto;
    min-width: 200px;
    padding: 10px 0;
    background: linear-gradient(90deg, cadetblue, cornflowerblue);
    border: solid #78c708 3px;
    border-radius: 5px;
    transition: 1s;
    font-size: 20px;
    font-family: source-code-pro, monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;;
    color: black;
    cursor: pointer;
}

.EndGameButton:focus {
    outline: none;
    border: solid grey 3px;
}

.EndGameButton:hover {
    border: solid #78c708 3px;
}


