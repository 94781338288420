.Timer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
    font-size: 2rem;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .Timer {
        font-size: 1rem;
        margin: 1rem 3rem;
    }
}